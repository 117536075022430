import React, { useEffect, useState } from "react"
import { Row, Col, Button, Alert } from "react-bootstrap"
import { baseURL } from "../config/baseURLPath"
import axios from "axios"
import { useHistory } from "react-router-dom"
import "./sponsorcss.css";

const Dashboard = () => {
  const history = useHistory();
  const [dashboardData, setDashboardData] = useState("");
  let VendorData = JSON.parse(localStorage.getItem('user'))
  const addNewProgram = () =>{
    history.push("/service-partner/course-list")
  }  

  useEffect(()=>{
    try{
      const userData = JSON.parse(localStorage.getItem("user"))
      getDashboard(userData.AdminUser.institution_id)
    }catch(e){
      console.log(e)
    }
  }, [])


  const getDashboard = (id) => {
    axios({
      method: "get",
      url: baseURL + "getVendorDashboard/" + id,
      headers: { "access-token": localStorage.getItem("csr_token") },
    })
      .then((response) => {
        console.log(response, "DashBoard");
  
        if (response?.status === 200) {
          setDashboardData(response?.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching dashboard data:", error);
      });
  };
  

  return (
    <>
      <div className="contentBox">
      {/* {VendorData?.VendorData?.ta_merchant_id ? */}
        <Row>
          <Col style={{display:"flex", alignItems:"flex-end", justifyContent:"flex-end", marginBottom:"15px"}}>
            <Button onClick={addNewProgram} className="primaryAdminButton me-2"  >Course List</Button>
            <Button onClick={()=>history.push("beneficiaries-list")} className="primaryAdminButton" >Students List</Button>
          </Col>
        </Row> 
        {/* : 
        <Row>
          <Col>
          <Alert variant={'danger'}>
              <b>Sorry!</b> Please register yourself on TA to continue on OptShine.
          </Alert>
          </Col>
        </Row> }  */}
        <Row className="numbersRow">
          <Col className="numberBoxDiv">
            <p className="numbersText" style={{color:"black"}}>{dashboardData!=="" ? dashboardData?.Courses : 0}</p>
            <div className="color-divider green"></div>
            <p className="" style={{color:"black"}}>Total Courses</p>
          </Col>
          <Col className="numberBoxDiv">
            <p className="numbersText" style={{color:"black"}}>{dashboardData !==""? dashboardData?.Students : 0}</p>
            <div className="color-divider yellow"></div>
            <p className="" style={{color:"black"}}>Total Students</p>
          </Col>
          <Col className="numberBoxDiv">
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Dashboard
